export const apps = [
  {
    "id": 0,
    appName:"deprem-bildirimleri-turkiye",
    status: false,
    icon : "../deprem/deprem-icon.png",
    name : "Deprem Bildirimleri Türkiye",
    description : "Deprem Bildirimleri Türkiye uygulaması ile Türkiye'de meydana gelen depremler hakkında anlık bildirim alabilirsiniz.",
    descriptionDetail:[
      "• İstanbul Büyükşehir Belediyesi'nden alınan VS30 zemin haritası ile bulunduğunuz bölgenin deprem riskini değerlendirin.",
      "• Belirlediğiniz minimum deprem büyüklüğüne göre özel bildirimler alın. Böylece sadece sizin için önemli olan depremler hakkında bilgilendirilirsiniz.",
      "• Her depremin yeri, derinliği, büyüklüğü, mesafesi ve kaynağı hakkında detaylı bilgiler edinin."

    ],
    appStoreUrl : "https://apps.apple.com/tr/app/deprem-bildirimleri-t%C3%BCrkiye/id6480133853?platform=iphone",
    googlePlayUrl : "",
    images : [
      {
        url: '../deprem/1.jpg',
      },
      {
        url: '../deprem/2.jpg',
      },
      {
        url: '../deprem/3.jpg',
      },
      {
        url: '../deprem/4.jpg',
      },
      {
        url: '../deprem/5.jpg',
      },
      {
        url: '../deprem/6.jpg',
      },
      {
        url: '../deprem/7.jpg',
      },
    ],
    privacyPolicyUrl : "https://tuwenti-documents.s3.eu-central-1.amazonaws.com/deprem-bildirimleri-turkiye.html"

  },
  {
    "id": 1,
    appName:"image-to-xls",
    status: true,
    icon : "../image-convertor/icon.png",
    name : "Sheet Ai",
    description : "Sheet Ai is an image to Excel converter app that allows you to convert your images into an Excel file.",
    descriptionDetail:[
      "• Convert your images into an Excel file.",
      "• Share the converted Excel file.",
      "• Download the converted Excel file.",
      "• Delete the converted Excel file."
    ],
    appStoreUrl : "https://apps.apple.com/us/app/sheet-ai/id6478458862",
    images : [
      {
        url: '../image-convertor/1.jpg',
      },
      {
        url: '../image-convertor/2.jpg',
      },
      {
        url: '../image-convertor/3.jpg',
      },
      {
        url: '../image-convertor/4.jpg',
      },
      {
        url: '../image-convertor/5.jpg',
      }
    ],
    privacyPolicyUrl : "https://tuwenti-documents.s3.eu-central-1.amazonaws.com/sheetai/sheet-ai-privacy-policy.html"
  },
  {
    "id": 2,
    appName:"find-imposter",
    status: false,
    icon : "../chat-ai/icon.png",
    name : "Find Imposter",
    description : "Find Imposter is a game that allows you to find the imposter among the crewmates.",
    descriptionDetail:[
      "• Find the imposter among the crewmates.",
      "• Highly intelligent Ai.",
      "• Share the game with your friends.",
    ],
    appStoreUrl : "https://apps.apple.com/app/find-imposter/id6504367325",
    images : [
      {
        url: '../chat-ai/1.jpeg',
      },
      {
        url: '../chat-ai/10.jpeg',
      },
      {
        url: '../chat-ai/3.jpeg',
      },
      {
        url: '../chat-ai/8.jpeg',
      },
      {
        url: '../chat-ai/9.jpeg',
      }
    ],
    privacyPolicyUrl : "https://tuwenti-documents.s3.eu-central-1.amazonaws.com/chatai/privacy-policy.html"
  },
  {
    "id": 3,
    appName:"logo-ai",
    status: true,
    icon : "../logo-ai/icon.png",
    name : "Vincent: Create Logo,Art,Dream",
    description : "Vincent is an app that allows you to create a logo for your brand using Ai.",
    descriptionDetail:[
      "• Create a logo for your brand using Ai.",
      "• Share the logo with your friends.",
      "• More than 30+ styles.",
      "• Download the logo.",
    ],
    appStoreUrl : "https://apps.apple.com/app/id6602546043?platform=iphone",
    images : [
      {
        url: '../logo-ai/1.png',
      },
      {
        url: '../logo-ai/2.png',
      },
      {
        url: '../logo-ai/3.png',
      },
      {
        url: '../logo-ai/4.png',
      },
    ],
    privacyPolicyUrl : "../logo-ai/logo-ai-privacy-policy.html"
  }
]